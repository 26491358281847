import React, { useMemo, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { size, escapeRegExp } from 'lodash';
import clsx from 'clsx';

import SearchBar from 'components/SearchBar';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(),
  },
  list: {
    maxHeight: '30vh',
    overflowY: 'auto',
  },
  client: {
    padding: theme.spacing(),
    fontSize: '1.125rem',
    fontWeight: 500,
    transition: theme.transitions.create(['background-color']),
    '&:hover': {
      // @todo: variablize?
      backgroundColor: theme.palette.secondary.light,
      cursor: 'pointer',
    },
  },
  selected: {
    backgroundColor: '#e4e4e4',
  },
  searchBar: {
    border: '1px solid #808080',
    borderRadius: 4,
    backgroundColor: '#fff',
  },
}));

const noop = () => {};
const ClientSelector = props => {
  const classes = useStyles();
  const { clients = [], onSelect = noop, selectedClient, idKey = 'id' } = props;
  const [term, setTerm] = useState('');

  const search = term => setTerm(term);
  const clearSearch = () => setTerm('');

  const filteredClients = useMemo(() => {
    if (!term) {
      return clients;
    }
    const re = new RegExp(escapeRegExp(term), 'i');
    return clients.filter(client => re.test(client.name));
  }, [term, clients]);

  return (
    <div className={classes.root}>
      <SearchBar
        classes={{ root: classes.searchBar }}
        onChange={search}
        onCancelSearch={clearSearch}
        inputProps={{ 'aria-label': 'client-search-input' }}
      />
      <div className={classes.list} data-test-id="search-results">
        {size(filteredClients) === 0 && <div>No clients found</div>}
        {size(filteredClients) > 0 &&
          filteredClients.map(client => (
            <div
              key={client.id}
              className={clsx(classes.client, selectedClient && selectedClient[idKey] === client[idKey] && classes.selected)}
              onClick={() => onSelect(client)}
            >
              {client.name}
            </div>
          ))}
      </div>
    </div>
  );
};

export default ClientSelector;
