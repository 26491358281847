export default function InvertList({ className }) {
  return (
    <svg viewBox="0 0 25 25" className={className}>
      <path
        d="M19.9 5.6h3.4c.5 0 .9.4.9.9v3.4c0 .5-.4.9-.9.9h-3.4c-.5 0-.9-.4-.9-.9V6.5c.1-.5.5-.9.9-.9zM19.9 14.2h3.4c.5 0 .9.4.9.9v3.4c0 .5-.4.9-.9.9h-3.4c-.5 0-.9-.4-.9-.9v-3.4c.1-.5.5-.9.9-.9zM1.7 5.6h13.7c.5 0 .9.4.9.9v3.4c0 .5-.4.9-.9.9H1.7c-.5 0-.9-.4-.9-.9V6.5c.2-.5.6-.9.9-.9zM1.7 14.2h13.7c.5 0 .9.4.9.9v3.4c0 .5-.4.9-.9.9H1.7c-.5 0-.9-.4-.9-.9v-3.4c.2-.5.6-.9.9-.9z"
        fill="none"
        stroke="currentColor"
      />
    </svg>
  );
}
