import React, { forwardRef } from 'react';
import { styled } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';
import { GridContainer, GridDateList, GridLeftSlot, GridRightSlot, GridSlotContent } from './GridComponents';
import { useHistory } from 'react-router-dom';

import Add from '@mui/icons-material/Add';
import { useBuilderStore } from './store';
import GridSupplierCalendar from './GridSupplierCalendar';

import { useInView } from 'react-intersection-observer';
import GridPricePerPerson from './GridPricePerPerson';
import LightningBolt from 'components/v2/Icons/LightningBolt';
import GridRooms from './GridRooms';
import { useHasSupplier, useSupplierSegments } from './hooks';

// @todo: used with preferred accommodations / positive impact once implemented
// import FlagMark from 'components/v2/Icons/FlagMark';
// import HeartMark from 'components/v2/Icons/HeartMark';

const Container = styled(GridContainer)({
  height: 78,
});

const PinButton = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderTopRightRadius: theme.shape.borderRadius,
  borderBottomRightRadius: theme.shape.borderRadius,
  color: theme.palette.common.white,
  textAlign: 'center',
  marginTop: 2,
  marginBottom: 2,
  position: 'relative',
  background: 'linear-gradient(to bottom, #285054, #123438, #FF6E00)',
  backgroundSize: '100% 200%',
  backgroundPosition: '0 0',
  transition: theme.transitions.create(['background-position']),
  cursor: 'pointer',
  width: 18,
  '&:hover': {
    backgroundPosition: '0 100%',
  },
}));

const Info = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  justifyContent: 'center',
  color: theme.palette.primary.main,
  flex: 1,
}));

const EllipsisTypography = styled(Typography)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const SlotLeft = styled(GridLeftSlot)({
  display: 'flex',
  flexDirection: 'row',
  gap: 20,
});

const SlotRight = styled(GridRightSlot)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const ShowAvailabilityButton = styled(Button)({
  textTransform: 'unset',
  borderRadius: 20,
  paddingLeft: 100,
  paddingRight: 100,
  margin: '0 auto',
});

const LightningIcon = styled('div', {
  shouldForwardProp: prop => prop !== 'online',
})(({ online }) => ({
  visibility: online ? 'visible' : 'hidden',
  lineHeight: 1,
  '& svg': {
    width: 14,
    height: 'auto',
  },
}));

const AddIcon = styled(Add, {
  shouldForwardProp: prop => prop !== 'pinned',
})(({ theme, pinned }) => ({
  transform: pinned ? 'rotate(45deg)' : '',
  transition: theme.transitions.create(['transform']),
  fontSize: 18,
}));

// @todo: used with preferred accommodations / positive impact once implemented
// const Options = styled('div')(({ theme }) => ({
//   display: 'flex',
//   flexDirection: 'row',
//   alignItems: 'center',
//   justifyContent: 'center',
//   gap: 3,
// }));
// const Icon = ({ theme }) => ({
//   width: 20,
//   height: 'auto',
//   color: theme.palette.primary.main,
// });
// const FlagMarkIcon = styled(FlagMark)(Icon);
// const HeartMarkIcon = styled(HeartMark)(Icon);

const GridViewRow = forwardRef(function GridViewRow(props, ref) {
  const { camp = {}, dates = [], date, inView, isPinned, isToggled, toggleSupplierAvailability, toggleAccommodationPin } = props;

  // camp:
  //   {
  //     "data": {
  //       "supplierCode": "ONE005",
  //       "campName": "138 Marine Luxury Beachfront Guesthouse",
  //       "country": "South Africa",
  //       "campInfo": "",
  //       "campFeatures": "[]",
  //       "dmcArrangedOnly": false,
  //       "online": false,
  //       "consultantInteractionRequired": true,
  //       "restOfWorld": false,
  //       "minChildAge": 0,
  //       "regionName": "Hermanus"
  //     },
  //     "supplierCode": "ONE005",
  //     "enabled": true
  //   }

  const history = useHistory();
  const supplierCode = camp.supplierCode;
  const hasSupplier = useHasSupplier(supplierCode);
  const segments = useSupplierSegments(supplierCode);
  const viewDetails = useBuilderStore(state => state.actions.accommodations.viewDetails);
  const onRoomClick = () => {
    viewDetails({ ...camp, item: camp });
    history.push('/itinerary-builder/details');
  };

  return (
    <Container ref={ref} data-test-id={`list-card-${supplierCode}`}>
      <SlotLeft>
        <PinButton
          data-test-id="pin-accommodation-button"
          onClick={() => {
            toggleAccommodationPin({ ...camp });
          }}
        >
          <AddIcon pinned={isPinned} />
        </PinButton>
        <Info>
          <EllipsisTypography variant="subtitle1">{camp.campName}</EllipsisTypography>
          <EllipsisTypography variant="caption">{[camp.regionName, camp.country].filter(Boolean).join(', ')}</EllipsisTypography>
        </Info>
        {/* @todo: used with preferred accommodations / positive impact once implemented */}
        {/* <Options>
          <Tooltip
            title={
              <div>
                <div style={{ marginBottom: 10 }}>POSITIVE IMPACT ACCOMMODATION.</div>
                <div>Our sales team recommends avoiding this accommodation in the summer, it is too hot. </div>
              </div>
            }
          >
            <FlagMarkIcon />
          </Tooltip>

          <Tooltip title="Preferred accommodation">
            <HeartMarkIcon />
          </Tooltip>
        </Options> */}
      </SlotLeft>

      <GridSlotContent>
        <GridDateList>
          {!isPinned && !isToggled && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1 /*, justifyContent: 'center' */ }}>
              <ShowAvailabilityButton
                variant="outlined"
                onClick={() => toggleSupplierAvailability(supplierCode)}
                endIcon={
                  <LightningIcon online={camp.online}>
                    <LightningBolt />
                  </LightningIcon>
                }
              >
                Show Availability
              </ShowAvailabilityButton>

              <GridPricePerPerson inView={inView} date={date} supplierCode={supplierCode} />
            </div>
          )}
          {(isPinned || isToggled) && <GridSupplierCalendar camp={camp} supplierCode={supplierCode} dates={dates} inView={inView} />}
        </GridDateList>
      </GridSlotContent>
      <SlotRight>
        <GridRooms segments={segments} hasSupplier={hasSupplier} camp={camp} onClick={onRoomClick} />
      </SlotRight>
    </Container>
  );
});

export default function GridViewRowWrapper(props) {
  const { camp = {} } = props;
  const supplierCode = camp.supplierCode;
  const { ref, inView } = useInView();
  const date = useBuilderStore(state => state.data.date);
  const isPinned = useBuilderStore(state => state.data.pinnedAccommodationMap[supplierCode]);
  const isToggled = useBuilderStore(state => state.ui.showAvailabilityBySupplierCode?.[supplierCode]);
  const toggleSupplierAvailability = useBuilderStore(state => state.actions.toggleSupplierAvailability);
  const toggleAccommodationPin = useBuilderStore(state => state.actions.accommodations.toggleAccommodationPin);

  return (
    <GridViewRow
      {...props}
      ref={ref}
      inView={inView}
      isPinned={isPinned}
      isToggled={isToggled}
      toggleSupplierAvailability={toggleSupplierAvailability}
      toggleAccommodationPin={toggleAccommodationPin}
      date={date}
    />
  );
}
