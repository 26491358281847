import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import BarsIcon from './Icons/Bars';
import BoxesIcon from './Icons/Boxes';
import InvertList from './Icons/InvertList';
import { styled } from '@mui/material/styles';

const InvertListIcon = styled(InvertList)({
  width: '16px !important',
});

const useStyles = makeStyles(
  {
    root: {
      '& .MuiToggleButton-root.Mui-selected': {
        backgroundColor: '#525E6A',
        color: '#fff',
      },
      '& .MuiToggleButtonGroup-groupedHorizontal:not(:last-child)': {
        borderRadius: 0,
      },
      '& .MuiToggleButtonGroup-groupedHorizontal:not(:first-child)': {
        borderRadius: 0,
      },
      '& svg': {
        width: 12,
        height: 'auto',
      },
    },
    toggle: {
      '&.Mui-selected': {
        backgroundColor: '#525E6A',
        color: '#fff',
      },
    },
    selected: {},
  },
  {
    name: 'CardListModeButtonGroup',
  },
);

const CardListModeButtonGroup = ({ value, onChange, 'data-test-id': dataTestId }) => {
  const classes = useStyles();
  return (
    <ToggleButtonGroup className={classes.root} value={value} onChange={onChange} exclusive aria-label="View Mode">
      <ToggleButton
        value="grid"
        aria-label="Grid Mode"
        classes={{ root: classes.toggle }}
        data-test-id={`${dataTestId ? `${dataTestId}-` : ''}grid-view-button`}
      >
        <BarsIcon />
      </ToggleButton>
      <ToggleButton
        value="list"
        aria-label="List Mode"
        classes={{ root: classes.toggle }}
        data-test-id={`${dataTestId ? `${dataTestId}-` : ''}list-view-button`}
      >
        <BoxesIcon />
      </ToggleButton>
      <ToggleButton
        value="card"
        aria-label="Card Mode"
        classes={{ root: classes.toggle }}
        data-test-id={`${dataTestId ? `${dataTestId}-` : ''}card-view-button`}
      >
        <InvertListIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default CardListModeButtonGroup;
