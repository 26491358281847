import { Controller } from 'react-hook-form';
import Input from './Input';

export default function InputRhf({ name, control, label, rules, placeholder, InputProps, onValueChange, ...rest }) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        const handleChange = event => {
          const newValue = event.target.value;
          onChange(newValue); // same as before
          if (onValueChange) {
            onValueChange(name, newValue); // use the custom handler
          }
        };

        return (
          <Input
            {...rest}
            InputLabelProps={{ shrink: true }}
            error={error?.message}
            onChange={handleChange}
            value={value ?? ''}
            fullWidth
            label={label}
            placeholder={placeholder ? placeholder : label}
            InputProps={InputProps}
          />
        );
      }}
    />
  );
}
