import React from 'react';
import clsx from 'clsx';
import { useBuilderStore } from './store';
import { values } from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import { shallow } from 'zustand/shallow';

import ListCard from './ListCard';
import GridViewRow from './GridViewRow';

const useStyles = makeStyles(
  {
    root: {
      display: 'flex',
      flexDirection: 'column',
      borderBottom: '1px solid #E9E1D7',
      marginBottom: 33,
      paddingBottom: 10,
      gap: 23,
    },
    card: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: 20,
      alignItems: 'stretch',
    },
  },
  {
    name: 'PinList',
  },
);

// @todo: this is basically a dupe of AccommodationsList
const PinsList = function({ dates = [] }) {
  const classes = useStyles();
  const pinnedAccommodations = useBuilderStore(state => values(state.data.pinnedAccommodationMap), shallow);
  const viewMode = useBuilderStore(state => state.data.viewMode);
  return (
    <div data-test-id="pinned-accommodations-list" className={clsx(classes.root, classes[viewMode])} /*ref={parent}*/>
      {pinnedAccommodations.map(item => {
        return viewMode === 'grid' ? (
          <GridViewRow key={item.supplierCode} camp={item} dates={dates} />
        ) : (
          <ListCard key={item.supplierCode} item={item} {...item} label={item.campName} description={item.campInfo} />
        );
      })}
    </div>
  );
};

export default PinsList;
