import React, { useCallback } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { FormControl, FormHelperText, TextField, InputAdornment } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import Tooltip from 'components/v2/Tooltip';
import { merge, mergeWith } from 'lodash';
import { noop } from 'common';

const useStyles = makeStyles(
  theme => ({
    inputRoot: {
      backgroundColor: '#fff',
      gap: theme.spacing(),
      '&:after': {
        borderBottom: `2px solid ${theme.palette.secondary.main}`,
      },
      paddingLeft: props => (props.multiline ? 12 : 0),
    },
    required: {
      fontSize: 12,
      color: '#c2c2c2',
    },
  }),
  {
    name: 'Input',
  },
);

const Input = React.forwardRef((props, ref) => {
  const { clearable, onClear, ...rest } = props;
  const classes = useStyles(props);
  const getInputProps = useCallback(
    props => {
      const inputClasses = { classes: { root: classes.inputRoot } };
      let inputProps = merge({}, props.InputProps || {}, inputClasses);
      if (props.multiline && !props.minRows && !props.maxRows) {
        inputProps = merge(inputProps, {
          minRows: 4, // MUI does not allow minRows or maxRows to be used in conjunction with a fixed rows prop
        });
      }
      if (props.required) {
        inputProps = merge(inputProps, {
          endAdornment: (
            <InputAdornment position="end">
              <span className={classes.required}>required</span>
            </InputAdornment>
          ),
        });
      }
      if (clearable) {
        inputProps = mergeWith(
          inputProps,
          {
            endAdornment: (
              <InputAdornment position="end">
                <div onClick={onClear || noop} role="button" aria-label="clear">
                  <Tooltip title="Clear">
                    <div className="hoverable">
                      <CloseIcon />
                    </div>
                  </Tooltip>
                </div>
              </InputAdornment>
            ),
          },
          (objValue, srcValue) => {
            // `mergeWith` prevents clash with inputProps.endAdornment and endAdornment.
            // NB: Attach `key="description"` to inputProps.endAdornment.
            return objValue ?? srcValue;
          },
        );
      }
      if (props.endAdornment) {
        inputProps = merge(inputProps, {
          endAdornment: (
            <InputAdornment position="end" key="description">
              {props.endAdornment}
            </InputAdornment>
          ),
        });
      }
      return inputProps;
    },
    [classes, clearable, onClear],
  );

  return (
    <FormControl error={Boolean(props.error)} fullWidth={props.fullWidth}>
      <TextField
        {...rest}
        error={Boolean(props.error)}
        InputProps={getInputProps(props)}
        InputLabelProps={{ shrink: true }}
        inputRef={ref}
      />
      <FormHelperText>{props.error ? props.error : ' '}</FormHelperText>
    </FormControl>
  );
});

export default Input;
