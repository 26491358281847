import React, { memo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import logger from 'itrvl-logger';

import AvailabilityCalendarVisibilityWrapper from './AvailabilityCalendarVisibilityWrapper';
import ShowAvailabilityCard from './ShowAvailabilityCard';
import PinAccommodation from './PinAccommodation';
import Image from './Image';
import ViewDetailButton from './ViewDetailButton';
import CampFeatures from './CampFeatures';

import CrosshairIcon from './icons/Crosshairs';
import HeartIcon from './icons/Heart';

import { useBuilderStore } from './store';
import { isUndefined, size } from 'lodash';
import ItrvlIcon from 'common/components/Icons/ItrvlIcon';
import ChildRestrictions from './ChildRestrictions';
import AccommodationPricePerPerson from './AccommodationPricePerPerson';

const log = logger(__filename);
log.trace(__filename);

const useStyles = makeStyles(
  {
    root: {
      display: 'flex',
      height: 303,
      '& .react-datepicker': {
        width: 300,
      },
      '& .react-datepicker__month-container': {
        width: '100%',
      },
      '& .date-picker-picker-own-range': {
        backgroundColor: 'red !important',
        border: '1px solid red',
      },
    },
    content: {
      backgroundColor: '#fff',
      display: 'flex',
      flexDirection: 'column',
      gap: 9,
      padding: 15,
      flex: 1,
      marginRight: props => (!props.hasCal && !props.showAvailability ? 282 : 0),
      '& > header': {
        fontWeight: 400,
        fontSize: 20,
        lineHeight: 1.4,
        color: '#123438',
        margin: 0,
        display: 'flex',
        gap: 10,
        '& > div': {
          marginTop: 3,
        },
      },
    },
    media: {
      position: 'relative',
      width: 325,
      aspectRatio: 1.7777777778,
      flexShrink: 0,
      '& img': {
        objectFit: 'cover',
        width: '100%',
        height: '100%',
      },
    },
    controls: {
      position: 'absolute',
      zIndex: 1,
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px 15px',
    },
    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: 'rgba(0,0,0,0.5)',
    },
    country: {
      display: 'flex',
      flexDirection: 'row',
      gap: 8,
      fontSize: 12,
      alignItems: 'center',
    },
    description: {
      fontSize: 12,
      lineHeight: 1.4166666667,
      display: '-webkit-box',
      WebkitLineClamp: 3,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
    },
    dateHeader: {
      width: '100%',
    },
    btnControls: {
      marginTop: 'auto',
      display: 'flex',
      gap: 8,
    },
    add: {
      backgroundColor: '#000',
      color: '#fff',
    },
    calendarContainer: {
      width: 282,
      height: 303,
    },

    calendarShown: {
      borderLeft: '1px solid #BCBEB8',
    },

    // card view, overrides to default "list" mode
    card: {
      width: 300,
      flexDirection: 'column-reverse',
      height: 'auto',
      paddingBottom: 0,
      justifyContent: 'flex-end',
      backgroundColor: '#fff',
      borderRadius: 4,
      // boxShadow: theme.shadows[1],
      '& $calendarShown': {
        border: 'none',
      },
      '& $content': {
        flex: 1,
        width: 'auto',
        backgroundColor: 'transparent',
      },
      '& $description': {
        display: 'none',
      },
      '& $media': {
        display: 'none',
      },
    },

    counts: {
      marginTop: 10,
      display: 'flex',
      gap: 20,
    },
    onlineIcon: {
      width: 25,
      height: 'auto',
    },
    featuresSection: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  {
    name: 'IB2ListCard',
  },
);

const ListCard = props => {
  // @todo: maybe don't rename fields
  const {
    campName: label,
    country,
    campInfo: description,
    /*media,*/ supplierCode,
    featuredImage,
    item,
    regionName,
    campFeatures,
    online,
    restOfWorld,
    consultantInteractionRequired,
  } = props;

  const date = useBuilderStore(state => state.data.date);
  const showAvailability = !isUndefined(date);
  const viewMode = useBuilderStore(state => state.data.viewMode);
  const isPinned = useBuilderStore(state => state.data.pinnedAccommodationMap[supplierCode]);
  const classes = useStyles({ hasCal: isPinned, showAvailability });
  const isToggled = useBuilderStore(state => state.ui.showAvailabilityBySupplierCode?.[supplierCode]);

  // @todo: deprecate these
  // const addSupplierToItinerary = useBuilderStore(state => state.actions.accommodations.addSupplierToItinerary);
  // const [startDate, endDate] = useBuilderStore(state => state.data.selectedMap[supplierCode] || [], shallow);

  return (
    <div data-test-id={`list-card-` + supplierCode} className={clsx(classes.root, classes[viewMode])} /*ref={parent}*/>
      <div className={classes.media} data-test-id="camp-media-container">
        <div className={classes.overlay} />
        <div className={classes.controls}>
          <HeartIcon />
          <PinAccommodation {...props} />
        </div>
        <Image s3Key={featuredImage?.s3Key} />
      </div>
      <div className={classes.content} data-test-id="camp-content-container">
        <header>
          {viewMode === 'card' && <PinAccommodation {...props} />}
          {label}
        </header>
        <div className={classes.country}>
          <div className={classes.countryIcon}>
            <CrosshairIcon />
          </div>
          {regionName}, {country}
        </div>
        <div className={classes.description}>{description}</div>
        <div className={classes.featuresSection}>
          <div className={classes.counts}>
            {size(campFeatures) > 0 && campFeatures[0] !== '' && <CampFeatures campFeatures={campFeatures} />}
            <ChildRestrictions item={item} />
          </div>
          <div className={classes.counts}>
            {online === true && <ItrvlIcon name="live" className={classes.onlineIcon} />}
            {process.env.REACT_APP_DEBUG_IB2 && restOfWorld === true && (
              <ItrvlIcon name="world" className={classes.onlineIcon} data-test-id="live-availability" />
            )}
            {process.env.REACT_APP_DEBUG_IB2 && consultantInteractionRequired === true && (
              <ItrvlIcon name="zero" className={classes.onlineIcon} />
            )}
          </div>
        </div>
        <AccommodationPricePerPerson date={date} supplierCode={supplierCode} />
        <div className={classes.btnControls}>
          <ViewDetailButton {...props} />
        </div>
      </div>

      {date && (
        <div className={clsx(classes.calendarContainer, classes.calendarShown)} data-test-id="camp-calendar-container">
          {!isPinned && !isToggled && <ShowAvailabilityCard supplierCode={supplierCode} />}
          {(isPinned || isToggled) && <AvailabilityCalendarVisibilityWrapper supplierCode={supplierCode} item={item} />}
        </div>
      )}

      {/* )} */}
    </div>
  );
};

export default memo(ListCard);
