import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import NiceModal from '@ebay/nice-modal-react';
import clsx from 'clsx';
import { noop } from 'common';
import { useSnackbar } from 'notistack';

import AddTravelersToRoomModal from './AddTravelersToRoomModal';
import Tooltip from 'components/v2/Tooltip';
import RoomCost from './RoomCost';
import Avatar from '@mui/material/Avatar';
import { Button, CircularProgress, Fade } from '@mui/material';
import FillRoomButton from './FillRoomButton';

import UserIcon from 'components/v2/Icons/User';
import BedIcon from './icons/Bed';
import ChildIcon from 'components/v2/Icons/Child';
import MultiUserIcon from './icons/MultiUser';

import { getMinChildAge } from 'itrvl-types';
import { useBuilderStore } from './store';
import { useSegmentRemainingTravelers } from './hooks';
import { travelersToFillRoom } from './utils';

import logger from 'itrvl-logger';
const log = logger(__filename);
log.trace(__filename);

const GUTTER = 18;
const useStyles = makeStyles(
  theme => ({
    '@global': {
      '.room-filled-snack': {
        bottom: 130,
        position: 'relative',
        pointerEvents: 'none',
      },
    },
    root: {
      display: 'flex',
      backgroundColor: '#fff',
      gap: GUTTER,
      position: 'relative',
    },
    cover: {
      position: 'absolute',
      inset: 0,
      backgroundColor: 'rgba(255,255,255,0.5)',
      zIndex: 1,
    },
    room: {
      padding: GUTTER,
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    name: {
      fontSize: 20,
    },
    data: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 20,
    },
    icon: {
      width: 14,
    },
    avatar: {
      width: 24,
      height: 24,
      fontSize: 12,
      backgroundColor: '#6D6156',
    },
    add: {
      display: 'flex',
      alignItems: 'center',
      gap: 40,
      borderLeft: '1px solid #F7F4F0',
      padding: GUTTER,
    },
    info: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      fontSize: 14,
      lineHeight: 1.4285714286,
    },
    cost: {
      color: theme.palette.primary.main,
    },
    inclusions: {
      cursor: 'pointer',
      fontSize: 12,
      lineHeight: 1,
      textTransform: 'uppercase',
    },
    inclusionsContent: {
      display: '-webkit-box',
      WebkitLineClamp: 3,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
    },
    count: {
      display: 'flex',
      alignItems: 'center',
      gap: 10,
      '& > span': {
        marginRight: 10,
      },
    },
    childIcon: {
      width: 12,
    },
    multiIcon: {
      width: 18,
    },
    controls: {
      display: 'flex',
      flexDirection: 'row',
      gap: 10,
    },
  }),
  {
    name: __filename,
  },
);

const RoomCard = ({ segmentId, room, numAlreadySelected, inclusionsLoading, inclusions, hasDates, disabled, canFill, isQuoteOnly }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  // @todo: rename to selectedSegmentId internally!
  const selectedSegmentId = useBuilderStore(state => state.ui.selectedRoom);
  const { adults, children } = useSegmentRemainingTravelers(selectedSegmentId);
  const addRoomToSegment = useBuilderStore(state => state.actions.accommodations.addRoomToSegment);
  const childrenAges = useBuilderStore(state => state.data.childrenAges);
  const minChildAge = getMinChildAge(children, childrenAges);
  const areChildrenTooYoungForRoom =
    minChildAge !== undefined && room.minChildAge !== undefined && minChildAge < room.minChildAge ? true : false;

  const handleRoom = () => {
    NiceModal.show(AddTravelersToRoomModal, { segmentId, room });
  };

  const onFillRoom = () => {
    const { adults: adultsToAdd, children: childrenToAdd, adultsErr, minErr } = travelersToFillRoom(room, adults, children);
    const commonSnackProps = {
      anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      className: 'room-filled-snack',
    };

    if (adultsErr) {
      return enqueueSnackbar('Could not fill room. You must have at least one adult per room with children.', {
        variant: 'error',
        ...commonSnackProps,
      });
    }

    if (minErr) {
      return enqueueSnackbar('Could not fill room. Not enough travelers to fill minimum pax requirement.', {
        variant: 'error',
        ...commonSnackProps,
      });
    }

    addRoomToSegment(selectedSegmentId, { room, adults: adultsToAdd, children: childrenToAdd });
    enqueueSnackbar('Room filled and added to itinerary.', {
      variant: 'success',
      ...commonSnackProps,
    });
  };

  let showCount = true;
  let roomDisabled = false;
  if (room.count === -2 || room.count === -1) {
    showCount = false;
    roomDisabled = false;
    // May be available, don't show count
  } else {
    roomDisabled = disabled || room.count - numAlreadySelected <= 0;
  }
  if (isQuoteOnly) {
    showCount = false;
    roomDisabled = false;
  }
  return (
    <div className={classes.root}>
      {roomDisabled && <div className={classes.cover} />}
      <div className={classes.room}>
        <div className={classes.name}>
          {room.uniqueName}
          {process.env.REACT_APP_DEBUG_IB2 ? ` [${room.roomTypeId}-${room.optionKey}]` : ''}
          {/*{process.env.REACT_APP_DEBUG_IB2 && room.count ? ` (Remaining rooms available for selected dates: ${room.count})` : ''}*/}
        </div>
        <div className={classes.data}>
          <div className={classes.count}>
            <Tooltip
              maxWidth={300}
              placement="top"
              title={`This room accommodates a maximum of ${room.guestNormal} travelers, which can be comprised of up to ${room.normalAdults} adults and up to ${room.normalChildren} children`}
            >
              <span className="hoverable">Max Capacity</span>
            </Tooltip>
            <div className={clsx(classes.icon, classes.multiIcon)}>
              <MultiUserIcon />
            </div>
            <Avatar className={classes.avatar}>{room.guestNormal}</Avatar>
          </div>

          <div className={classes.count}>
            <span>Max Adults</span>
            <div className={classes.icon}>
              <UserIcon />
            </div>{' '}
            <Avatar className={classes.avatar}>{room.normalAdults}</Avatar>
          </div>

          {room.normalChildren > 0 && (
            <div className={classes.count}>
              <span>Max Children</span>
              <div className={clsx(classes.icon, classes.childIcon)}>
                <ChildIcon />
              </div>{' '}
              <Avatar className={classes.avatar}>{room.normalChildren}</Avatar>
              {process.env.REACT_APP_DEBUG_IB2 && room.minChildAge ? ` > ${room.minChildAge}` : ``}
            </div>
          )}

          {showCount && room.count ? (
            <div className={classes.count}>
              <span>Remaining Rooms</span>
              <div className={classes.icon}>
                <BedIcon />
              </div>{' '}
              <Avatar className={classes.avatar}>{room.count - numAlreadySelected}</Avatar>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className={classes.add}>
        <div className={classes.info}>
          {hasDates && !disabled && (
            <span className={classes.cost}>
              <RoomCost cost={room?.cost} />
            </span>
          )}
          <Tooltip
            maxWidth={500}
            title={
              <>
                {inclusionsLoading ? (
                  <CircularProgress color="inherit" />
                ) : (
                  // @todo: what to do in the no inclusions case?
                  <div className={classes.inclusionsContent}>{inclusions ? inclusions : 'Unable to find inclusions.'}</div>
                )}
              </>
            }
            placement="top"
          >
            <span className={classes.inclusions}>What's included</span>
          </Tooltip>
        </div>

        <div className={classes.controls}>
          <Fade in={Boolean(canFill)}>
            {/* need a div to hold the ref for the fade */}
            <div>
              <FillRoomButton onClick={onFillRoom} disabled={adults + children === 0 || areChildrenTooYoungForRoom} />
            </div>
          </Fade>

          <Tooltip interactive placement="top" title={areChildrenTooYoungForRoom && `Children permitted ${room?.minChildAge} years and up`}>
            <span>
              <Button
                variant="outlined"
                disabled={!hasDates || areChildrenTooYoungForRoom}
                onClick={hasDates && !disabled ? handleRoom : noop}
              >
                {!hasDates && 'Select Dates'}
                {hasDates && !disabled && !areChildrenTooYoungForRoom && 'Add Room'}
                {hasDates && !disabled && areChildrenTooYoungForRoom && 'Invalid Child Age'}
                {hasDates && disabled && 'Not Available'}
              </Button>
            </span>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default RoomCard;
