export default function DoubleChevronRight({ className }) {
  return (
    <svg viewBox="0 0 11 14" className={className}>
      <path
        d="m1.56.373 5.474 5.715c.12.15.21.329.21.508 0 .18-.09.36-.21.479L1.56 12.789c-.269.3-.748.3-1.017.03-.3-.269-.3-.718-.03-1.017l5.026-5.236L.512 1.361A.714.714 0 0 1 .542.343a.714.714 0 0 1 1.017.03Z"
        fill="currentColor"
      />
      <path
        d="m4.509.373 5.475 5.715c.12.15.21.329.21.508 0 .18-.09.36-.21.479l-5.475 5.714c-.27.3-.748.3-1.017.03-.3-.269-.3-.718-.03-1.017l5.026-5.236-5.026-5.205a.714.714 0 0 1 .03-1.018.714.714 0 0 1 1.017.03Z"
        fill="currentColor"
      />
    </svg>
  );
}
