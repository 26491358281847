import { styled } from '@mui/material/styles';

const BORDER_COLOR = '#E1E1E1';
const GUTTER = 20;

const GridContainer = styled('div')({
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'row',
  // @todo: do responsive?
  minWidth: 'calc(1440px - 64px)',
  maxWidth: 'calc(1440px - 64px)',
});

const GridLeftSlot = styled('div')({
  width: 300,
  flexShrink: 0,
  borderRight: `1px solid ${BORDER_COLOR}`,
  paddingRight: GUTTER,
});

const GridSlotContent = styled('div', { name: 'GridSlotContent' })({
  width: '100%',
  paddingLeft: 30,
  paddingRight: 30,
  paddingTop: 20,
  paddingBottom: 20,
  flexDirection: 'column',
  justifyContent: 'center',
  display: 'flex',
});

const GridDateList = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const GridRightSlot = styled('div')({
  width: 300,
  flexShrink: 0,
  borderLeft: `1px solid ${BORDER_COLOR}`,
  paddingLeft: GUTTER,
});

const GridDateSlot = styled('div')({
  width: 30,
  height: 30,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export { GridContainer, GridLeftSlot, GridSlotContent, GridRightSlot, GridDateSlot, GridDateList };
