import { useMemo, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import { Button } from '@mui/material';

import PointIcon from 'agent/src/components/v2/Icons/Point';
import CalendarIcon from './icons/Calendar';
import CrosshairsIcon from './icons/Crosshairs';
import Input from 'agent/src/components/v2/FormElements/Input';
import { formatDigestDates } from './utils';
import { useBuilderStore } from './store';
import RegionSelector from 'components/v2/FormElements/RegionSelector';

const GUTTER = 20;
const useStyles = makeStyles(
  theme => ({
    root: {
      width: '100%',
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      padding: GUTTER,
      top: 0,
      zIndex: 1,
      borderRadius: 4,
      transition: theme.transitions.create(['background-color', 'color']),
    },

    selected: {
      backgroundColor: '#fff',
      color: theme.palette.primary.main,
    },

    content: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: theme.spacing(2),
    },

    item: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 10,
      width: '100%',
    },

    icon: {
      lineHeight: 0,
      '& svg': {
        width: '100%',
        height: 'auto',
        color: '#fff',
        transition: theme.transitions.create(['color']),
      },
    },

    iconSelected: {
      '& svg': {
        color: theme.palette.primary.main,
      },
    },

    hasRooms: {
      borderBottom: '1px dotted #fff',
      transition: theme.transitions.create(['border-color']),
      cursor: 'pointer',
      '&.selected': {
        borderColor: theme.palette.primary.main,
        display: 'flex',
        alignItems: 'center',
        gap: 10,
        '& svg': {
          width: 10,
        },
      },
    },
    calendar: {
      width: 20,
    },
    locationDot: {
      width: 30,
    },
    crosshairs: {
      width: 24,
    },
    input: {
      position: 'relative',
      top: 7,
      width: '100%',
    },
    btn: {
      color: '#fff',
      borderColor: '#fff',
      transition: theme.transitions.create(['border-color', 'color']),
    },
    btnSelected: {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  }),
  {
    name: 'DetailOwnAccommodationSegment',
  },
);

export default function DetailOwnAccommodationSegment(props) {
  const { id } = props;
  const segment = useBuilderStore(state => state.data.segmentsById[id]);

  const raw = useBuilderStore(state => state.ui.regions.raw);
  const regions = useMemo(() => {
    if (!raw) return [];
    const regionsCopy = [...raw];
    if (!regionsCopy) return [];
    regionsCopy.sort((a, b) => a.country.localeCompare(b.country));
    return regionsCopy;
  }, [raw]);

  const { startDate, endDate } = segment;
  const classes = useStyles();
  const dateText = formatDigestDates(startDate, endDate);

  const selectRoom = useBuilderStore(state => state.actions.segments.selectRoom);
  const patchSegment = useBuilderStore(state => state.actions.accommodations.patchSegment);
  const selectedRoomId = useBuilderStore(state => state.ui.selectedRoom);
  const selected = selectedRoomId === id;

  const [title, setTitle] = useState(segment.title || '');
  const [region, setRegion] = useState();
  const [regionIsPristine, setRegionIsPristine] = useState(true);
  const [regionError, setRegionError] = useState('');

  // when the page loads and the segment already has a region, but the list of region options hasn't been fetched yet,
  // we need this useEffect to set the region as one of the options, instead of the region name that comes off of the segment
  useEffect(() => {
    if (Array.isArray(regions) && regions.length > 0 && segment?.regionName && !region && regionIsPristine) {
      const regionOption = regions.find(r => r.name === segment.regionName);
      setRegion(regionOption);
      setRegionIsPristine(false);
    }
  }, [regions, region, segment, setRegion, regionIsPristine, setRegionIsPristine]);

  const handleRegionChange = (_, value) => {
    setRegion(value);
  };

  const toggleEditing = () => {
    if (selected) {
      if (!region) {
        setRegionError('You must select a region');
        return;
      }
      setRegionError('');
      patchSegment(id, {
        title,
        regionCode: region?.regionCode,
        regionName: region?.name,
        country: region?.country,
      });
      selectRoom(null);
    } else {
      selectRoom(id);
    }
  };

  return (
    <div className={clsx(classes.root, selected && classes.selected)}>
      <div className={clsx('inner', classes.content)}>
        {/* calendar display */}
        <div className={classes.item}>
          <div className={clsx(classes.icon, classes.calendar, selected && classes.iconSelected)}>
            <CalendarIcon />
          </div>
          {dateText || 'No Dates Selected'}
        </div>

        {/* location selector */}
        <div className={classes.item}>
          {selected ? (
            <div className={classes.input}>
              <RegionSelector error={regionError} options={regions} value={region} onChange={handleRegionChange} />
            </div>
          ) : (
            <>
              <div className={clsx(classes.icon, classes.locationDot, selected && classes.iconSelected)}>
                <PointIcon />
              </div>
              {region?.name ? `${region.name}, ${region.country}` : 'No Region Selected'}
            </>
          )}
        </div>

        {/* description input */}
        <div className={classes.item}>
          {selected ? (
            <div className={classes.input}>
              <Input fullWidth placeholder="Accommodation Title" value={title} onChange={evt => setTitle(evt.target.value)} />
            </div>
          ) : (
            <>
              <div className={clsx(classes.icon, classes.crosshairs, selected && classes.iconSelected)}>
                <CrosshairsIcon />
              </div>
              {title || 'Own Arranged Accommodation'}
            </>
          )}
        </div>

        <div className={classes.item}>
          <Button className={clsx(classes.btn, selected && classes.btnSelected)} onClick={toggleEditing} variant="outlined">
            {selected ? 'Done Editing' : 'Edit'}
          </Button>
        </div>
      </div>
    </div>
  );
}
