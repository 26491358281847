import { styled } from '@mui/material/styles';
import Bed from './icons/Bed';
import Tooltip from 'components/v2/Tooltip';
import { Badge } from '@mui/material';
import { noop } from 'common';
import User from 'components/v2/Icons/User';
import Child from 'components/v2/Icons/Child';
import Bullseye from 'components/v2/Icons/Bullseye';

const BedIcon = styled('div')({
  lineHeight: 0,
  '& svg': {
    top: 2,
    position: 'relative',
    width: 26,
    height: 'auto',
  },
});

const ChildIcon = styled(Child)({
  width: 10,
});

const UserIcon = styled(User)({
  width: 12,
});

const RoomHeader = styled('div')(({ theme }) => ({
  marginBottom: 10,
  paddingBottom: 10,
  borderBottom: `1px solid ${theme.palette.secondary.main}`,
  fontSize: '1.25rem',
}));

const BullseyeIcon = styled(Bullseye)({
  width: 25,
});

function RoomsTooltip({ segments = [] }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
      {segments.map((segment = {}) => {
        const count = segment.rooms?.length ?? 0;
        return (
          <div key={segment.id}>
            <RoomHeader>
              {count} Room{count === 0 || count > 1 ? 's' : ''} Selected
            </RoomHeader>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
              {segment.rooms.map((room = {}) => {
                const data = room.room ?? {};
                return (
                  <div key={room.id}>
                    <div>
                      {data.uniqueName}: {data.name}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                      {room.adults > 0 && (
                        <span>
                          {room.adults} <UserIcon />
                        </span>
                      )}
                      {room.children > 0 && (
                        <span>
                          {room.children} <ChildIcon />
                        </span>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}

const Info = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 5,
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
  textDecoration: 'underline',
  transition: theme.transitions.create(['color']),
  '&:hover': {
    color: theme.palette.secondary.main,
    cursor: 'pointer',
  },
}));

function getContent({ camp = {}, segments = [], hasSupplier, roomCount, onClick = noop }) {
  if (!hasSupplier) {
    return <></>;
  }

  if (camp.ownAccommodation) {
    return (
      <Info onClick={onClick}>
        <BullseyeIcon />
        Select Location
      </Info>
    );
  }

  if (hasSupplier && (camp.consultantInteractionRequired || camp.dmcArrangedOnly)) {
    return (
      <Tooltip title="Room selection not required">
        <div style={{ textTransform: 'uppercase' }}>Dates Selected</div>
      </Tooltip>
    );
  }

  if (hasSupplier && roomCount === 0) {
    return (
      <Info onClick={onClick}>
        {' '}
        <BedIcon>
          <Bed />
        </BedIcon>
        <span>Select Rooms</span>
      </Info>
    );
  }

  if (hasSupplier && roomCount > 0) {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
        <Tooltip title={<RoomsTooltip segments={segments} />}>
          <Badge
            badgeContent={roomCount}
            color="secondary"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <BedIcon>
              <Bed />
            </BedIcon>
          </Badge>
        </Tooltip>
        <Info onClick={onClick} className="link">
          Edit Rooms
        </Info>
      </div>
    );
  }
}

export default function GridRooms(props) {
  const { camp = {}, hasSupplier, segments = [], onClick = noop } = props;

  let roomCount = 0;
  if (hasSupplier) {
    roomCount = segments.reduce((acc, segment) => {
      acc += segment?.rooms?.length ?? 0;
      return acc;
    }, 0);
  }

  return <div>{getContent({ camp, segments, hasSupplier, roomCount, onClick })}</div>;
}
