import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import NiceModal from '@ebay/nice-modal-react';
import { size } from 'lodash';

import AddTravelersToRoomModal from './AddTravelersToRoomModal';
import Tooltip from 'components/v2/Tooltip';
import { Button } from '@mui/material';
import RoomCost from './RoomCost';

import EditIcon from '@mui/icons-material/Edit';
import BedIcon from './icons/Bed';
import CalendarIcon from './icons/Calendar';
import ChildIcon from 'components/v2/Icons/Child';
import UserIcon from 'components/v2/Icons/User';
import TimesCircleIcon from './icons/TimesCircle';

import { formatDigestDates } from './utils';
import { useBuilderStore } from './store';
import { useSegmentRemainingTravelers } from './hooks';
import EditPenIcon from 'components/v2/Icons/EditPen';

const GUTTER = 20;
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    padding: GUTTER,
    top: 0,
    zIndex: 1,
    borderRadius: 4,
    transition: theme.transitions.create(['background-color', 'color']),
  },

  selected: {
    backgroundColor: '#fff',
    color: theme.palette.primary.main,
  },

  content: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    alignItems: 'center',
    justifyItems: 'center',
  },

  item: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },

  icon: {
    width: 25,
    lineHeight: 0,
    // height: 30,
    '& svg': {
      width: '100%',
      height: 'auto',
      color: '#fff',
      transition: theme.transitions.create(['color']),
    },
  },

  iconSelected: {
    '& svg': {
      color: theme.palette.primary.main,
    },
  },

  hasRooms: {
    borderBottom: '1px dotted #fff',
    transition: theme.transitions.create(['border-color']),
    cursor: 'pointer',
    '&.selected': {
      borderColor: theme.palette.primary.main,
      display: 'flex',
      alignItems: 'center',
      gap: 10,
      '& svg': {
        width: 10,
      },
    },
  },

  edit: {
    width: 18,
  },

  calendar: {
    width: 20,
    // height: 20,
  },

  child: {
    width: 14,
  },

  adult: {
    width: 20,
  },

  humanCount: {
    display: 'flex',
    gap: 10,
    alignItems: 'center',
  },

  // tooltip
  tooltip: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    gap: 20,
    // alignItems: 'center',
    '& h1': {
      fontFamily: 'Athelas',
      margin: 0,
    },
    '& > div': {
      paddingBottom: 20,
      borderBottom: '1px solid #fff',
    },
    '& > :last-child': {
      padding: 0,
      border: 0,
    },
  },
  tooltipIcon: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    '& svg': {
      width: 15,
      lineHeight: 0,
    },
  },
  room: {
    display: 'flex',
    gap: 20,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tooltipCount: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  tooltipClose: {
    width: 20,
    cursor: 'pointer',
    transition: theme.transitions.create(['color']),
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },

  controls: {
    display: 'flex',
    gap: 20,
  },

  btn: {
    color: '#fff',
    borderColor: '#fff',
    transition: theme.transitions.create(['border-color', 'color']),
  },
  btnSelected: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
}));

const DetailSegment = props => {
  const { id } = props;
  const segment = useBuilderStore(state => state.data.segmentsById[id]);
  const { startDate, endDate, rooms = [] } = segment;
  const classes = useStyles();
  const dateText = formatDigestDates(startDate, endDate);

  const removeRoom = useBuilderStore(state => state.actions.accommodations.removeRoom);
  const { adults, children } = useSegmentRemainingTravelers(id);

  const tripChildren = useBuilderStore(state => state.data.children);

  const hasRooms = size(rooms) > 0;

  const selectRoom = useBuilderStore(state => state.actions.segments.selectRoom);
  const selectedRoomId = useBuilderStore(state => state.ui.selectedRoom);
  const selected = selectedRoomId === id;

  return (
    <div className={clsx(classes.root, selected && classes.selected)}>
      <div className={clsx('inner', classes.content)}>
        <div className={classes.item}>
          <div className={clsx(classes.icon, classes.calendar, selected && classes.iconSelected)}>
            <CalendarIcon />
          </div>
          {dateText || 'No Dates Selected'}
        </div>
        <Tooltip
          interactive
          placement="bottom"
          title={
            hasRooms ? (
              <div className={classes.tooltip}>
                {rooms.map((props, index) => {
                  const { adults, children, room, id } = props;
                  return (
                    <div key={index} className={classes.room}>
                      <div>
                        <h1>{room.name}</h1>
                        <div className={classes.tooltipCount}>
                          <RoomCost cost={room?.cost} />

                          <div className={classes.tooltipIcon}>
                            {adults} <UserIcon />
                          </div>
                          {children > 0 && (
                            <div className={classes.tooltipIcon}>
                              {children} <ChildIcon />
                            </div>
                          )}
                        </div>
                      </div>
                      {/* @todo: maybe confirm the deletion? */}

                      <div className={classes.controls}>
                        <div
                          className={classes.tooltipClose}
                          onClick={() =>
                            NiceModal.show(AddTravelersToRoomModal, {
                              segmentId: segment.id,
                              room,
                              edit: props,
                              deleteRoom: () => removeRoom(segment.id, id),
                            })
                          }
                        >
                          <EditIcon />
                        </div>

                        <div className={classes.tooltipClose} onClick={() => removeRoom(segment.id, id)}>
                          <TimesCircleIcon />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              // display no tooltip if there are no rooms
              ''
            )
          }
        >
          <div className={classes.item}>
            <div className={clsx(classes.icon, selected && classes.iconSelected)}>
              <BedIcon />
            </div>
            <div className={clsx(hasRooms && classes.hasRooms, 'selected')}>
              {size(rooms)} Rooms Selected
              {hasRooms && <EditPenIcon />}
            </div>
          </div>
        </Tooltip>
        <div className={classes.item}>
          <div className={classes.humanCount}>
            {adults}
            <div className={clsx(classes.icon, selected && classes.iconSelected)}>
              <UserIcon />
            </div>
          </div>
          {tripChildren > 0 && (
            <div className={classes.humanCount}>
              {children}
              <div className={clsx(classes.icon, classes.child, selected && classes.iconSelected)}>
                <ChildIcon />
              </div>
            </div>
          )}
          Unassigned Travelers
        </div>

        <div className={classes.item}>
          <Button
            className={clsx(classes.btn, selected && classes.btnSelected)}
            onClick={() => selectRoom(selected ? null : id)}
            variant="outlined"
          >
            {selected ? 'Done Selecting' : 'Select Rooms'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DetailSegment;
