export default function LightningBolt() {
  return (
    <svg viewBox="0 0 10 12">
      <path
        d="M2.77457 11.8804L3.51599 7.80254H0.550293L5.55491 0.0175781H6.66705L6.11098 4.83684H9.44739L3.8867 11.8804H2.77457Z"
        fill="currentColor"
      />
    </svg>
  );
}
