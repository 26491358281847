export default function DoubleChevronLeft({ className }) {
  return (
    <svg viewBox="0 0 9.9 12.9" className={className}>
      <path
        d="M8.6 12.6 3.2 6.9c-.2-.1-.3-.3-.3-.5s.1-.4.2-.5L8.6.2c.3-.3.7-.3 1 0 .3.3.3.7 0 1l-5 5.2 5 5.2c.3.3.3.7 0 1-.2.3-.7.3-1 0z"
        fill="currentColor"
      />
      <path
        d="M5.7 12.6.2 6.9c-.1-.1-.2-.3-.2-.5s.1-.4.2-.5L5.7.2c.3-.3.7-.3 1 0 .3.3.3.7 0 1l-5 5.2 5 5.2c.3.3.3.7 0 1-.3.3-.7.3-1 0z"
        fill="currentColor"
      />
    </svg>
  );
}
