import React from 'react';
import { startOfMonth } from 'date-fns';
import { formatDate } from 'utils/v2/dates';
import { usePpQuery } from './queries';
import { styled } from '@mui/material/styles';

const Text = styled('div', {
  shouldForwardProp: prop => prop !== 'shouldRender',
})(({ shouldRender }) => ({
  lineHeight: shouldRender ? 1 : 0,
  marginTop: shouldRender ? 5 : 0,
  overflow: 'hidden',
  transition: 'all 350ms ease-in-out',
  fontSize: 12,
}));

export default function GridPricePerPerson({ date, supplierCode, inView }) {
  // @todo: this collapses and then re-expands when you fetch new data, so we need some sort of transition state if we dislike this
  const startDateString = date && formatDate(startOfMonth(date));
  const query = usePpQuery(startDateString, supplierCode, inView && Boolean(startDateString));
  const shouldRender = !query.isError && !query.isLoading && query.isFetched && query?.data?.roomRatePP > 0;
  return (
    <Text shouldRender={shouldRender}>From ${query?.data?.roomRatePP > 0 ? Math.round(query?.data?.roomRatePP) : '--'} per person</Text>
  );
}
