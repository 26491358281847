import { addDays, format, subDays } from 'date-fns';
import { head, last } from 'lodash';
import { styled } from '@mui/material/styles';

import ChevronLeftIcon from './icons/ChevronLeft';
import ChevronRightIcon from './icons/ChevronRight';
import { useBuilderStore } from './store';
import { GridContainer, GridDateList, GridDateSlot, GridLeftSlot, GridRightSlot, GridSlotContent } from './GridComponents';
import { useEffect, useRef, useState } from 'react';
import { formatDateRange } from 'utils/dates';
import DoubleChevronLeft from 'components/v2/Icons/DoubleChevronLeft';
import DoubleChevronRight from 'components/v2/Icons/DoubleChevronRight';
import { ToggleButton } from '@mui/material';

const Controls = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
  alignItems: 'center',
});

const Cluster = styled('div')({
  width: 120,
  display: 'flex',
  flexDirection: 'row',
  gap: 5,
});

const LeftCluster = styled(Cluster)({
  justifyContent: 'flex-end',
});

const RightCluster = styled(Cluster)({
  justifyContent: 'flex-start',
});

const STICKY_OFFSET = 60;
const Container = styled(GridContainer, {
  shouldForwardProp: prop => prop !== 'sticky',
})(({ sticky }) => ({
  position: 'sticky',
  top: STICKY_OFFSET,
  zIndex: 1,
  transition: 'all 350ms ease-in-out',
  borderBottom: sticky ? '1px solid #E9E1D7' : '1px solid transparent',
  marginBottom: 20,
  maxWidth: 'calc(1440px - 64px)',
  minWidth: 'calc(1440px - 64px)',
}));

const Content = styled(GridSlotContent, { shouldForwardProp: prop => prop !== 'sticky' })(({ sticky }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  ...(sticky ? { paddingTop: 5, paddingBottom: 5, gap: 5 } : { gap: 0 }),
  transition: 'all 350ms ease-in-out',
}));

const DateSlot = styled(GridDateSlot, { name: 'DateSlot', shouldForwardProp: prop => prop !== 'sticky' })(({ sticky }) => ({
  transition: 'all 350ms ease-in-out',
  lineHeight: 1,
  ...(sticky ? { fontSize: 12, height: 'auto' } : {}),
}));

const DateRangeText = styled('span', { shouldForwardProp: prop => prop !== 'sticky' })(({ sticky }) => ({
  transition: 'all 350ms ease-in-out',
  flex: 1,
  textAlign: 'center',
  ...(sticky ? { fontSize: 14 } : {}),
}));

const NavButton = styled(ToggleButton, { shouldForwardProp: prop => prop !== 'sticky' })(({ sticky }) => ({
  width: sticky ? 20 : 36,
  height: sticky ? 20 : 36,
  transition: 'all 350ms ease-in-out',
  color: '#000',
  ...(sticky ? { padding: 0 } : {}),
  '& svg': {
    width: 'auto',
    height: sticky ? 12 : 19,
    transition: 'all 350ms ease-in-out',
  },
}));

// a little hack-age, so we can identify when the menu has gone "sticky" to apply different styles (shrink a bunch of stuff for screen real-estate)
const DEFAULT_OBSERVER_SETTINGS = { threshold: [1] };
const useDetectSticky = (observerSettings = DEFAULT_OBSERVER_SETTINGS, ref) => {
  const [isSticky, setIsSticky] = useState(false);
  const newRef = useRef();
  ref ||= newRef;

  useEffect(() => {
    const cachedRef = ref.current,
      observer = new IntersectionObserver(([e]) => setIsSticky(e.intersectionRatio < 1), observerSettings);
    observer.observe(cachedRef);
    return () => {
      observer.unobserve(cachedRef);
    };
  }, [observerSettings, ref]);

  return [isSticky, ref];
};

const OBSERVER_SETTINGS = {
  threshold: [1],
  rootMargin: `-${STICKY_OFFSET + 1}px 0px 0px 0px`,
};

export default function GridDateControl({ dates }) {
  const setDate = useBuilderStore(state => state.actions.date.setDate);
  const start = head(dates);

  const [isSticky, ref] = useDetectSticky(OBSERVER_SETTINGS);

  const next = () => setDate(addDays(start, 1));

  const nextWeek = () => setDate(addDays(start, 7));

  const prev = () => setDate(subDays(start, 1));

  const prevWeek = () => setDate(subDays(start, 7));

  return (
    <Container ref={ref} sticky={isSticky}>
      <GridLeftSlot />
      <Content sticky={isSticky}>
        <Controls>
          <LeftCluster>
            <NavButton value="" onClick={prevWeek} sticky={isSticky}>
              <DoubleChevronLeft />
            </NavButton>
            <NavButton value="" onClick={prev} sticky={isSticky}>
              <ChevronLeftIcon />
            </NavButton>
          </LeftCluster>
          <DateRangeText sticky={isSticky}>
            {formatDateRange(start, last(dates), { appendSameYear: true, appendSameMonth: false })}
          </DateRangeText>
          <RightCluster>
            <NavButton value="" onClick={next} sticky={isSticky}>
              <ChevronRightIcon />
            </NavButton>
            <NavButton value="" onClick={nextWeek} sticky={isSticky}>
              <DoubleChevronRight />
            </NavButton>
          </RightCluster>
        </Controls>
        <GridDateList>
          {dates.map(date => (
            <DateSlot key={date.getTime()} sticky={isSticky}>
              {format(date, 'EEEEEE')}
            </DateSlot>
          ))}
        </GridDateList>
      </Content>
      <GridRightSlot />
    </Container>
  );
}
